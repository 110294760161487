/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'move-out': {
    width: 18,
    height: 20,
    viewBox: '0 0 18 20',
    data: '<path pid="0" d="M11.177 5.42a.6.6 0 010-.85l3.37-3.37h-1.35a.6.6 0 110-1.2h2.8a.6.6 0 01.6.6v2.8a.6.6 0 11-1.2 0V2.05l-3.4 3.37a.6.6 0 01-.82 0zM0 9a2 2 0 012-2h14a2 2 0 012 2v2a2 2 0 01-2 2H2a2 2 0 01-2-2V9z" _fill="#000"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M16 8H2a1 1 0 00-1 1v2a1 1 0 001 1h14a1 1 0 001-1V9a1 1 0 00-1-1zM2 7a2 2 0 00-2 2v2a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2H2zM16 15H2a1 1 0 00-1 1v2a1 1 0 001 1h14a1 1 0 001-1v-2a1 1 0 00-1-1zM2 14a2 2 0 00-2 2v2a2 2 0 002 2h14a2 2 0 002-2v-2a2 2 0 00-2-2H2z" _fill="#000"/>'
  }
})
